<template>
  <div class="column is-2-desktop is-6-mobile">
    <div class="card border-bottom">
      <div class="card-image">
        <figure class="image is-2by3">
          <router-link :to="{ name: redirectName, params: {id: info.id } }">
            <img class="border-top" :src="image" alt="Placeholder image" />
          </router-link>
        </figure>
      </div>
      <div class="card-content" style="padding-bottom: 0.75rem;">
        <p class="title is-size-5-desktop is-size-6-mobile">{{title}}</p>
        <p class="subtitle is-7" v-if="media_type != 'person'">
          <span class="right-padding">{{ date }}</span>
          <span class="right-padding boxed">{{ certification }}</span>
          <span class="right-padding">{{ info.vote_average.toFixed(1) }}</span>
          <span class="right-padding" style="font-variant: small-caps">{{ info.media_type }}</span>
        </p>
      </div>

      <div
        v-if="info.overview !== '' && media_type != 'person'"
        class="content plot-container is-hidden-mobile"
      >
        <p class="plot">
          <span style="font-variant: small-caps; font-weight: 600;">plot</span>
          <br />
          {{info.overview | shorten}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  props: {
    metadata: Object,
    type: String
  },
  data() {
    return {
      info: this.metadata,
      media_type:
        this.metadata.media_type != undefined
          ? this.metadata.media_type
          : this.type,
      certification: "",
      notfound: require("@/assets/images/unknown.png"),
      personnotfound: require("@/assets/images/personnotfound.png")
    };
  },
  // watch: {
  //   metadata: {
  //     deep: true,
  //     handler() {
  //       console.log("watcher");
  //       this.handleCertifications();
  //     }
  //   }
  // },
  methods: {
    checkIfLegit(val) {
      var items = [undefined, null, "", " "];
      return items.every(item => item !== val);
    },
    handleCertifications() {
      if (this.media_type != "person") {
        // console.log("handling certs");
        let info = this.info;
        this.getCertification(
          info.id,
          this.media_type == "all" ? info.media_type : this.media_type
        ).then(cert => {
          this.certification = cert;
        });
      }
    },
    async getCertification(id, type) {
      console.log(type);
      if (type == "tv") {
        return tmdb.getTvCertification(id);
      } else if (type == "movie") {
        return tmdb.getMovieCertification(id);
      } else {
        return "NR";
      }
    }
  },
  mounted() {
    this.handleCertifications();
  },
  computed: {
    date() {
      var info = this.info;
      var media_type = this.media_type;
      var date;
      if (media_type != "person") {
        if (media_type == "movie") {
          date = info.release_date;
          return this.checkIfLegit(date) ? new Date(date).getFullYear() : "???";
        } else if (media_type == "tv") {
          date = info.first_air_date;
          return this.checkIfLegit(date) ? new Date(date).getFullYear() : "???";
        } else {
          return "???";
        }
      } else return "";
    },
    title() {
      let title =
        this.media_type != "person"
          ? this.info.title != undefined
            ? this.info.title
            : this.info.original_name
          : this.info.name;
      return title;
    },
    image() {
      let beginning = "https://image.tmdb.org/t/p/w500/";
      if (this.media_type == "person") {
        if (this.info.profile_path != null) {
          return beginning + this.info.profile_path;
        } else return this.personnotfound;
      } else {
        if (this.info.poster_path != null) {
          return beginning + this.info.poster_path;
        } else return this.notfound;
      }
    },
    redirectName() {
      let type = this.media_type;
      if (type == "movie") {
        return "movies";
      } else if (type == "tv") {
        return "tv";
      } else if (type == "person") {
        return "person";
      } else {
        return "undefined";
      }
    }
  },
  filters: {
    shorten(val) {
      if (val != undefined) {
        return val.length > 100 ? val.slice(0, 100) + "..." : val;
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.right-padding {
  margin-right: 5px;
  font-weight: 500;
  white-space: nowrap;
}

.boxed {
  text-align: center;
  padding-left: 1px;
  padding-right: 1px;
  border: 1.5px solid #4d4d4d;
  border-radius: 2px;
}

.boxed:empty {
  display: none;
}

.border-bottom {
  border-radius: 5px;
}

.border-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.plot-container {
  margin-left: 1rem;
  padding-bottom: 1rem;
}

.plot {
  border-left: 2px solid #4d4d4d;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
}
</style>