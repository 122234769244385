<template>
  <div class="field has-addons" style="float:right">
    <p class="control">
      <button title="Filter the search results" class="button is-rounded is-static" style="background-color: #09c0f4; border: none">
        <span>
          <font-awesome-icon icon="filter" style="color: white"></font-awesome-icon>
        </span>
      </button>
    </p>
    <p class="control">
      <button title="Show all results"
        class="button is-rounded"
        :class="{ 'is-info': selected == 'all'}"
        @click.prevent="handleClicks($event, 'all')"
      >
        <font-awesome-icon icon="globe"></font-awesome-icon>
        <span class="is-hidden-mobile" style="margin-left: 0.35rem">All</span>
      </button>
    </p>
    <p class="control">
      <button title="Only show movies"
        class="button is-rounded"
        :class="{ 'is-info': selected == 'movies'}"
        @click.prevent="handleClicks($event, 'movies')"
      >
        <font-awesome-icon icon="film"></font-awesome-icon>
        <span class="is-hidden-mobile" style="margin-left: 0.35rem">Movies</span>
      </button>
    </p>
    <p class="control">
      <button title="Only show TV shows"
        class="button is-rounded"
        :class="{ 'is-info': selected == 'tv'}"
        @click.prevent="handleClicks($event, 'tv')"
      >
        <font-awesome-icon icon="tv"></font-awesome-icon>
        <span class="is-hidden-mobile" style="margin-left: 0.35rem">TV</span>
      </button>
    </p>
    <p class="control">
      <button title="Only show people"
        class="button is-rounded"
        :class="{ 'is-info': selected == 'people'}"
        @click.prevent="handleClicks($event, 'people')"
      >
        <font-awesome-icon icon="user-ninja"></font-awesome-icon>
        <span class="is-hidden-mobile" style="margin-left: 0.35rem">People</span>
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: "filterbutton",
  data() {
    return {
      selected: "all"
    };
  },
  methods: {
    handleClicks(click, from) {
      if (this.selected != from) {
        this.selected = from;
        this.$emit("filterChanged", from);
      }
    }
  }
};
</script>