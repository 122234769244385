<template>
  <div class="field has-addons has-addons-centered">
    <!-- <div class="control">
      <button class="button is-light is-rounded">
        <font-awesome-icon icon="filter"></font-awesome-icon>
      </button>
    </div> -->
    <div class="control">
      <input
        v-model="query"
        @keyup.enter="handleQuerySubmit($event)"
        class="input is-rounded"
        placeholder="Search for movies, tv..."
      />
    </div>
    <div class="control">
      <button @click="handleQuerySubmit($event)" class="button is-primary is-rounded">
        <font-awesome-icon icon="search"></font-awesome-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: "Searchbar",
    data() {
        return {
            query: ""
        }
    },    
    methods: {
        handleQuerySubmit(el) {
            el.target.blur()
            this.$emit('querySubmitted', this.query);
        }
    }  
};
</script>