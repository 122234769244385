<template>
  <div>
    <phlix-navbar></phlix-navbar>

    <div class="container is-fluid">
      <div class="level">
        <div class="level-left">
          <h1 class="subtitle is-hidden-mobile" style="font-variant: small-caps">{{ searchmsg }}</h1>
        </div>
        <div class="level-right">
          <searchbar @querySubmitted="query = $event"></searchbar>
        </div>
      </div>
      <cards>
        <div class="column is-12">
          <div class="is-hidden-desktop" style="display: flex; justify-content: center">
            <filterbutton @filterChanged="filter = $event"></filterbutton>
          </div>
          <filterbutton
            @filterChanged="filter = $event"
            class="is-hidden-mobile is-hidden-tablet-only"
          ></filterbutton>
        </div>
        <card
          v-for="(item, index) in filtered"
          :metadata="item"
          :key="index"
          :type="filter | type"
        ></card>
      </cards>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" v-if="!firstrun">
        <div slot="no-results">
          <p class="subtitle is-5" style="padding-top:3rem; padding-bottom:3rem">{{ noResults }}</p>
        </div>
      </infinite-loading>
      <phlix-footer style="padding-bottom: 1rem"></phlix-footer>
    </div>
    <error v-if="errorIsOpen" @closeError="errorIsOpen = false">{{ errorMsg }}</error>
    <bottomnavbar item="search"></bottomnavbar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Searchbar from "@/components/Searchbar.vue";
import Card from "@/components/Card.vue";
import Cards from "@/components/Cards.vue";
import InfiniteLoading from "vue-infinite-loading";
import TMDb from "@/utils/TMDb.js";
import Error from "@/components/Error.vue";
import Footer from "@/components/Footer.vue";
import Bottomnavbar from "@/components/Bottomnavbar.vue";
import Filterbutton from "@/components/Filterbutton.vue";

const tmdb = new TMDb();

export default {
  name: "search",
  components: {
    "phlix-navbar": Navbar,
    "phlix-footer": Footer,
    Searchbar,
    Card,
    Cards,
    InfiniteLoading,
    Error,
    Bottomnavbar,
    Filterbutton
  },
  data() {
    return {
      query: "",
      info: [],
      filter: "all",
      page: 1,
      pages: 1,
      infiniteId: 0,
      firstrun: true,
      searchmsg: "popular movies",
      errorIsOpen: false,
      errorMsg: "Uh-oh :(. We've ran into a problem."
    };
  },
  watch: {
    query() {
      this.resetAndSearch();
    },
    filter(filter) {
      this.resetAndSearch();
    }
  },
  methods: {
    resetAndSearch() {
      this.page = 1;
      this.pages = 1;
      this.info = [];
      this.infiniteId += 1;
      this.search(this.query, this.filter);
    },
    search(query, filter) {
      tmdb
        .process(query, 1, this.filter)
        .then(resp => {
          let totalresults = resp.data.total_results;
          this.searchmsg =
            totalresults == 1
              ? totalresults + " result found"
              : totalresults + " results found";
          resp.data.results.forEach(item => {
            this.info.push(item);
          });
          this.page = resp.data.page;
          this.pages = resp.data.total_pages;
        })
        .catch(err => {
          this.errorIsOpen = true;
          this.errorMsg = err;
        });
    },
    infiniteHandler($state) {
      let nextpage = this.page + 1;
      tmdb
        .process(this.query, nextpage, this.filter)
        .then(resp => {
          console.log("Fetched page " + nextpage + " from infiniteHandler");
          resp.data.results.forEach(item => {
            this.info.push(item);
          });
          this.page = resp.data.page;
          this.page >= this.pages ? $state.complete() : $state.loaded();
        })
        .catch(err => {
          this.errorIsOpen = true;
          this.errorMsg = err;
        });
    }
  },
  mounted() {
    if (this.info.length == 0) {
      tmdb
        .process("", 1, this.filter)
        .then(resp => {
          this.info = resp.data.results;
          this.page = resp.data.page;
          this.pages = resp.data.total_pages;
          console.log("Fetched page " + resp.data.page + " from mounted");
          this.firstrun = false;
        })
        .catch(err => {
          this.errorIsOpen = true;
          this.errorMsg = err;
        });
    }
  },
  filters: {
    type(type) {
      switch (type) {
        case "all":
          return "all";
          break;
        case "movies":
          return "movie";
          break;
        case "tv":
          return "tv";
          break;
        case "people":
          return "person";
          break;
        default:
          return "all";
      }
    }
  },
  computed: {
    filtered() {
      return this.info.filter(item => item.media_type !== "person");
    },

    noResults() {
      return this.info.length == 0
        ? "No results found :("
        : "No more results :(";
    }
  }
};
</script>
