<template>
  <div class="modal is-active" style="z-index: 9999">
    <div class="modal-background"></div>
    <div class="modal-content box">
      <font-awesome-icon
        @click="$emit('closeError')"
        style="position: relative; float: right;"
        icon="times"
        aria-label="close"
      ></font-awesome-icon>
      <p class="title">Error</p>

      <article class="message is-danger">
        <div class="message-body">
          <p class="subtitle">{{ errormsg }}</p>
        </div>
      </article>

      <div class="buttons" style="float: right">
        <button @click="reload" class="button is-info is-rounded">
          <font-awesome-icon icon="redo" style="margin-right: 10px"></font-awesome-icon>Reload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  methods: {
    reload() {
      location.reload();
    }
  },
  computed: {
    errormsg() {
      let error = this.$slots.default[0].text;
      let e = Number(String(error).replace(/^\D+/g, ""));
      switch (e) {
        case 501:
          return "Invalid service: this service does not exist.";
          break;
        case 401:
          return "Authentication failed: You do not have permissions to access the service.";
          break;
        case 405:
          return "Invalid format: This service doesn't exist in that format.";
          break;
        case 422:
          return "Invalid parameters: Your request parameters are incorrect.";
          break;
        case 404:
          return "Invalid id: The pre-requisite id is invalid or not found.";
          break;
        case 403:
          return "Duplicate entry: The data you tried to submit already exists.";
          break;
        case 503:
          return "Service offline: This service is temporarily offline, try again later.";
          break;
        case 500:
          return "Internal error: Something went wrong, contact TMDb.";
          break;
        case 400:
          return "Validation failed.";
          break;
        case 406:
          return "Invalid accept header.";
          break;
        case 504:
          return "Your request to the backend server timed out. Try again.";
          break;
        case 429:
          return "Your request count (#) is over the allowed limit of (40).";
          break;
        default:
          return "Uh-oh! We've encountered an error";
          break;
      }
      return error;
    }
  }
};
</script>